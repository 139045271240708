import BaseService from "./base.service";

class InformationService {
    information(page = null, search = null,pagetype=null) {
        let params = [];
        params.push({'key':'pagetype', 'value':pagetype})
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('information', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('information/'+id);
    }
    add(information) {
        return BaseService.apiPostRequest('information/add', information);
    }
    delete(information) {
        return BaseService.apiPostRequest('information/delete/', information);
    }
    update(id, information) {
        return BaseService.apiPostRequest('information/'+id, information);
    }

    search(page=null) {
        return BaseService.apiGetRequest('information/search', page);
    }
}

export default new InformationService();